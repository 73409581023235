import React from 'react';
import cx from 'classnames';
import GuestNavLeft from './GuestNavLeft';
import GuestNavRight from './GuestNavRight';

import styles from './PublicRegistryNavContainer.module.less';

const PublicRegistryNavContainer = () => {
  return (
    <div className={styles.container}>
      <div className={cx('top-nav__container-v2', styles.publicRegistry)}>
        <GuestNavLeft />
        <GuestNavRight />
      </div>
    </div>
  );
};

export default PublicRegistryNavContainer;
