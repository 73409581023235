import styled from '@emotion/styled';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY, { SCREEN_MASSIVE } from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { PhoneIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Phone';
import { TruckDeliveryIcon } from '@zola/zola-ui/src/components/SvgIconsV3/TruckDelivery';
import CartContainer from 'components/nav/TopNav/CartContainer';

export const Container = styled.div`
  align-items: top;
  background: ${COLORS3.WHITE_100};
  display: flex;
  flex-direction: row;
  font-family: ${FONT.FONT_FAMILY_CIRCULAR};
  height: 60px;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${SCREEN_MASSIVE}px;
  padding: 5px ${SPACING.LG} 0;
  text-align: initial;
  width: 100%;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    padding: 5px ${SPACING.MD} 0;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

// @ts-expect-error self declared component
export const StyledLink = styled(LinkV2)`
  display: flex;
  align-items: center;
  font-size: ${FONT.FONT_SIZE_SMALLER};
  text-decoration: none;

  & + ${() => StyledLink} {
    margin: 0 0 0 26px;
  }

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }
`;

export const StyledPhoneIcon = styled(PhoneIcon)`
  margin: 0 4px 0 0;
`;

export const StyledTruckDeliveryIcon = styled(TruckDeliveryIcon)`
  margin: 0 4px 0 0;
`;

export const StyledCartContainer = styled(CartContainer)`
  margin: 5px 0 0 22px;
  position: relative;

  span {
    background-color: ${COLORS3.ROSE_100};
    left: 10px;
    position: absolute;
    top: -5px;
  }
`;
