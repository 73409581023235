import React from 'react';

import ZolaLogo from '@zola/zola-ui/src/components/ZolaLogo';

import styles from './TopNavLogo.module.less';

const TopNavLogo = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <a href="/" className={styles.home} target="_self" aria-label="Zola Homepage">
        <ZolaLogo className={styles.zolaRebrandLogo} />
      </a>
    </div>
  );
};

export default TopNavLogo;
