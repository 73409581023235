import React from 'react';
import classNames from 'classnames';

import TopNavLogo from 'components/nav/TopNav/TopNavLogo';

/**
 * Renders zola logo and label for registry
 * This component is only used
 * @returns {*}
 * @constructor
 */
const GuestNavLeft = () => (
  <ul className={classNames('top-nav__left navbar-left top-minimum-nav')}>
    <TopNavLogo />
    <li className="top-nav__nav-item top-nav__logo-label">Registry</li>
  </ul>
);

export default GuestNavLeft;
