import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import CartIcon from '@zola/zola-ui/src/components/SvgIcons/Cart';
import { ShoppingCartIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ShoppingCart';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';

import { showModal } from '../../../actions/ModalActions';
import { getCartSize } from '../../../actions/CartActions';

const {
  EVENTS: { OPEN_MODAL_EVENT },
} = NAV_DATA;

const CART_PAGE_V2_PATH = '/cart';

class CartContainer extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.openModalEventHandler = this.openModalEventHandler.bind(this);
    this.gettingCartSizeAction = this.gettingCartSizeAction.bind(this);
    this.renderIcon = this.renderIcon.bind(this);

    this.state = {
      cartSize: 0,
    };
  }

  componentDidMount() {
    const { handleGetCartSize } = this.props;
    handleGetCartSize().then((cartSize) => {
      this.setState({ cartSize });
    });

    window.addEventListener('NAV_UPDATE_CART_SIZE', this.gettingCartSizeAction);
    window.addEventListener(OPEN_MODAL_EVENT, this.openModalEventHandler, false);
  }

  componentWillUnmount() {
    window.removeEventListener('NAV_UPDATE_CART_SIZE', this.gettingCartSizeAction);
    window.removeEventListener(OPEN_MODAL_EVENT, this.openModalEventHandler);
  }

  gettingCartSizeAction(e) {
    const newCartSize = e?.detail || 0;
    this.setState({ cartSize: newCartSize });
  }

  openModalEventHandler(event) {
    if (event.detail === 'CART') {
      const { openModal } = this.props;
      openModal('CART_MODAL_V2', {}, { ariaLabel: 'Cart' });
    }
  }

  handleClick(e) {
    const { navigationLevelPosition, openModal, onClick } = this.props;
    e.preventDefault();
    trackNavigationClicked({
      business_category: 'UNATTRIBUTED',
      business_unit: 'UNATTRIBUTED',
      navigation_level_1: 'CART',
      navigation_level_1_position: navigationLevelPosition || 1,
      navigation_type: 'UTILITY',
    });
    if (typeof onClick === 'function') onClick();

    // If on the cart page, reload instead of showing the modal
    const pagePath = window.location.pathname;
    if (pagePath === CART_PAGE_V2_PATH) {
      window.location.reload();
      return;
    }
    openModal('CART_MODAL_V2', {}, { ariaLabel: 'Cart' });
  }

  renderIcon() {
    const { isPostAuthNav3Enabled, isNavV2 } = this.props;

    if (isPostAuthNav3Enabled) {
      return <ShoppingCartIcon title="Shopping cart" height={20} width={20} />;
    }
    if (isNavV2) {
      // Can we consolidate the icons here at least?
      return (
        <CartIcon
          className="nav-util__icon nav-cart__icon"
          title="Shopping cart"
          height={40}
          width={40}
        />
      );
    }
    return <ShoppingCartIcon title="Shopping cart" height={20} width={20} />;
  }

  render() {
    const { className, isDesktopNavV3, hideIfEmpty } = this.props;
    const { cartSize } = this.state;

    if (hideIfEmpty && cartSize === 0) return null;

    return (
      <LinkV2
        data-testid="nav-cart"
        aria-label="Cart"
        className={cx('top-nav__cart icon-link', className, 'nav-util__cart', {
          'icon-link-v3': isDesktopNavV3,
        })}
        href={CART_PAGE_V2_PATH}
        onClick={this.handleClick}
      >
        {this.renderIcon()}
        {cartSize > 0 && <span className="badge">{cartSize}</span>}
      </LinkV2>
    );
  }
}

CartContainer.propTypes = {
  openModal: PropTypes.func.isRequired,
  handleGetCartSize: PropTypes.func.isRequired,
  isNavV2: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isDesktopNavV3: PropTypes.bool,
  isPostAuthNav3Enabled: PropTypes.bool,
  navigationLevelPosition: PropTypes.number,
  hideIfEmpty: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  cartSize: state.cart.size,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (modalType, modalProps, modalOptions) => {
    dispatch(showModal(modalType, modalProps, modalOptions));
  },
  handleGetCartSize: () => dispatch(getCartSize()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);
