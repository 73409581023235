import React from 'react';
import classNames from 'classnames';

import CartContainer from 'components/nav/TopNav/CartContainer';

/**
 * Renders helper information and shopping cart
 * @returns {*}
 * @constructor
 */
const GuestNavRight = () => (
  <ul className={classNames('top-nav__right navbar-right top-minimum-nav')}>
    <li className="top-nav__nav-item top-nav__link hidden-sm hidden-xs">
      <a
        href="/faq/category/115000284811-for-gift-givers-wedding-guests"
        target="_blank"
        rel="noopener noreferrer"
        className="teal-hover text-capitalize"
      >
        About Zola
      </a>
    </li>
    <li className="top-nav__nav-item top-nav__link hidden-sm hidden-xs">
      <a href="tel:14086579652" target="_blank" rel="noopener noreferrer">
        {' '}
        <i className="zola-ui-icon zola-ui-icon-call" />
        1-408-657-ZOLA
      </a>
    </li>
    <li className="top-nav__nav-item top-nav__link hidden-sm hidden-xs">
      <div id="freeship-message">
        <a
          href="/faq/115002839971-does-zola-offer-free-shipping-"
          target="_blank"
          rel="noopener noreferrer"
          className="text-capitalize"
        >
          Free Shipping, Every Day!
        </a>
        <a
          href="/faq/115002839971-does-zola-offer-free-shipping-"
          target="_blank"
          rel="noopener noreferrer"
          id="freeship-details"
        >
          details
        </a>
      </div>
    </li>
    <li className="top-nav__nav-item top-nav__cart">
      <CartContainer isNavV2 />
    </li>
  </ul>
);

export default GuestNavRight;
