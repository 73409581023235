import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { WGetCartViewResponse } from '@zola/svc-web-api-ts-client';
import { AppDispatch } from 'reducers/index';

import type { CamelCasedPropertiesDeep } from 'type-fest';
import { receivedCartSizeAction, requestCartSizeAction } from './types/CartActionTypes';

export const getCartSize = () => (dispatch: AppDispatch) => {
  dispatch(requestCartSizeAction());

  return ApiService.get<CamelCasedPropertiesDeep<WGetCartViewResponse>>(
    `/website-nav/web-api/v1/cart`
  ).then((json) => {
    const length = json.data?.items?.length || 0;
    dispatch(receivedCartSizeAction(length));
    return length;
  });
};
